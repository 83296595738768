@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import 'styles/reset.scss';
@import 'styles/vars.scss';

*{
    box-sizing: border-box;
}

body{
    font-family: $font-family;
    margin: 0px;
    color: $primary;
    line-height: 1.5;
}
a{
    color: $link;
    text-decoration: none;
    outline: none;
}
.block{display: block}
.bold{ font-weight: bold }
.uppercase { text-transform: uppercase;}
.lowercase { text-transform: lowercase;}
code{ color: $error}

.truncate{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// right action arrow
.action{
    border-radius: 2px;
    border: 1px solid;
    height: 25px;
    width: 25px;
    padding: 5px;
    border-color:#f0efef;
    background: whitesmoke;
    cursor: pointer;
    transition: transform 0.2s;
    &.open{
        transform: rotate(-180deg);
    }
}

// form styles
select, input{
    display: inline-block;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: $secondary;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 2px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    &:disabled{
        background: gainsboro;
    }
}

// react-pagination overrides
.pagination{
    padding-left: 10px;
    margin: 20px 0px;
    li{
        display:inline-block; 
        cursor: pointer;
        text-align: center;
        border: 1px solid $link-dark;
        border-right:0px;
        &:hover{
            background: $link;
            a{
                color: $secondary;
            }
        }
        a{
            width: 100%;
            display: inline-block;
            padding: 10px 0px;
            width: 44px;
        }
        &.next{
            border-right: 1px solid $link-dark;
        }
        &.previous,&.next{
            font-weight: bolder;
        }
        &.active{
            background: $link;
            a{
                color: $secondary;
            }
        }
        &.disabled{
            cursor: default;
            border-color:gainsboro;
            a{
                color:gray;
                &:hover{
                    background:$secondary;
                }
            }
        }
    }
}

// loaders common styles
.loader{
    text-align:center;
    padding-top:20%;
}
.loader-results,.not-found{
    padding: 20px;
}

// Spinner 
// TODO: fix with postCSS
.spinner{
    -webkit-animation:spin .5s linear infinite;
    -moz-animation:spin .5s linear infinite;
    animation:spin .5s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

// react-datepicker overrides
.react-datepicker__tab-loop{
    display:inline-block;
}
