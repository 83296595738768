@import "styles/vars.scss";

.container {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  background: $secondary;
  border-top: 1px solid $secondary-dark;
}

.header {
  padding: 10px;
  border-color: $secondary-dark;
  text-transform: uppercase;
  div {
    padding-left: 10px;
    display: inline-block;
  }
  .preheader {
    display: block;
  }
}

.body {
  min-height: 410px;
}

.row {
  padding-left: 15px;
  display: inline-block;
  vertical-align: top;
  &_vendor {
    width: 100px;
  }
  &_account {
    width: 150px;
  }
  &_unit {
    width: 70px;
  }
  &_id {
    width: 180px;
  }
  &_active {
    width: 85px;
  }
  &_health {
    width: 90px;
  }
  &_fail {
    &_msg {
      width: 200px;
      font-size: 13px;
    }
    &_tag {
      width: 100px;
    }
    &_times {
      width: 60px;
    }
  }
}

.action {
  cursor: pointer;
  width: 200px;
  display: inline;
  border: 0px;
  border-radius: 2px;
  font-size: 15px;
  line-height: 2;
  text-transform: uppercase;
  transition: all 0.2s;
  &.success {
    background: #52ac59;
    border: 1px solid #289531;
    color: white;
    margin-right: 15px;
  }
  &.error {
    background: #ff5454;
    border: 1px solid #ca4c4c;
    color: white;
  }
  &:hover {
    transform: scale(1.1);
  }
}
