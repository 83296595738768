.status,.tag{
    color:white;
    background:#c0bdbd;
    text-align:center;
    border-radius: 2px;;
    padding: 0px 10px;
    display:inline-block;
    &_fail{
        background:#ff5f5f;
    }
    &_success{
        background: #52ac59;
    }
}
