@import 'styles/vars.scss';

.gateway{
    padding:10px 0px;
    display: block;
    border-top:1px solid $secondary-dark;
    &:nth-child(odd) {
        background: $secondary-dark-strong;
    }
}

.message{
    background: $error-light;
    border-radius:2px;
    border: 1px solid $error-light-strong;
    padding:0px 5px;
    color: $error;
    margin-bottom: 10px;
    word-wrap: break-word;
}

.vendor{
    font-weight: bolder;
}

.action{
    &_spinner{
        font-size:10px;
        margin-left:5px;
    }
    &_message{
        line-height: 1.8;
        border-radius: 2px;
        padding: 0px 5px;
        border: 1px solid;
        max-width:430px;
        display:inline-block;
        font-size:12px;
        &.error{
            background: $error-light;
            border-color: $error-light-strong;
            color: $error;
        }
        &.success{
            background: $success-dark;
            border-color: $success-dark-strong;
            color: $success;
        }
    }
    &_link, &_link_bottom{
        background: $link;
        color: $secondary;
        padding:3px 8px;
        border-radius: 2px;
        margin:0px 5px;
        border:0px;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 10px;
        display:inline-block;
        &:first-child{
            margin-left: 0px;
        }
        &_bottom{
            background: #a6a6a6;
            border: 1px solid #9d9d9d;
            line-height: 1.7;
            vertical-align: top;
        }
    }
    &_tag{
        background: $tertiary;
        color: $secondary;
        border-radius: 2px;
        padding: 5px 10px;
        margin-right:10px;
        text-transform: uppercase;
        font-size: 10px;
        display:inline-block;
        vertical-align: top;
        width:70px;
        text-align:center;
    }
    &_content{
        transition: max-height 0.15s ease-out;
        max-height: 0px;
        background: #00000002;
        overflow: hidden;
        margin-bottom: -10px;
        &_inner{
            border-top: 1px solid $secondary-dark;
            margin: 5px;
            margin-left: 15px;
            padding-top: 5px;
            &_left{
                min-width: 100px;
            }
        }
        &.open{
            max-height:200px;
        }
    }
}