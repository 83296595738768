$primary: #655655;
$secondary: #fff;
$secondary-dark: #ddd;
$secondary-dark-strong: #f9f9f9;
$tertiary: #454545;
$quaternary: #977efc;
$link: #00a6bb;
$link-dark: #00a6bb42;
$error: #ff7b7b;
$error-light: #fffafa;
$error-light-strong: #fff2f2;
$success: #4ead54;
$success-dark: #4ead541c;
$success-dark-strong: #4ead5445;

$font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
